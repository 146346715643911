import { ZodSchema, z } from "zod";
import {
  AccountPlan as PrismaAccountPlan,
  PaidFeature as PrismaPaidFeature,
  ProjectAuthType,
} from "@prisma/client";
import { AccountEntitlementsResponse } from "../services/zapier/types/types";

import { components } from "@zapier/api-clients/apis/permissions/types";
import { operations } from "@zapier/api-clients/apis/interfaces/types";

export type InterfaceOperations = operations;

export type GetJsonResponseFromOperation<
  OperationId extends keyof InterfaceOperations,
  StatusCode extends keyof InterfaceOperations[OperationId]["responses"],
> = InterfaceOperations[OperationId] extends {
  responses: Record<StatusCode, any>;
}
  ? InterfaceOperations[OperationId]["responses"][StatusCode]["content"]["application/json"]
  : never;

export type Operation = {
  type: string;
  path: string;
  validator: ZodSchema;
};

// When adding a new feature, add it to the PaidFeature enum in schema.prisma
export const paidFeatures = z.nativeEnum(PrismaPaidFeature);
export type PaidFeature = z.infer<typeof paidFeatures>;

export const accountPlan = z.nativeEnum(PrismaAccountPlan);
export type AccountPlan = z.infer<typeof accountPlan>;

export const premiumFeatures: PaidFeature[] = [
  "customColors",
  "branding",
  "embedding",
  "analytics",
  "customTokenLength",

  "passwordProtect",
  "managedUsers",

  "chatBotDataSources",
  "conditionalLogic",
  "fileSizeLimit10mb",
  "embedBlock",
  "layoutBlock",
  "stripePayment",
  "extendedFileUploads",

  /**
   * Stytch-related project authorization
   */
  "stytchAuth",
  "projectMagicLinkAuth",
];

export const advancedFeatures: PaidFeature[] = [
  ...premiumFeatures,
  "customDomain",
  "dynamicFilters",
  "hideZapierButton",
  "fileSizeLimit25mb",

  /**
   * Stytch-related project authorization
   */
  "projectSignInWithGoogleAuth",
  "projectApprovedDomainsAuth",
];

export const teamAccountFeatures: PaidFeature[] = ["collaborators"];

export const allFeatures: PaidFeature[] = Array.from(
  new Set(premiumFeatures.concat(advancedFeatures, teamAccountFeatures))
);

export const OPERATIONS_EXCLUDED_FROM_AUTH = [
  "chatbotsInterfaceCreate",
  "get_interfaces_list",
  "projectsCreate",
  "getProjectMetadata",
  "getProjectLinkedAssets",
  "get_projects_list",
  "getFormSubmissionCount",
  "v0_get_consumer_me",
] as const satisfies (keyof InterfaceOperations)[];

export type OperationsExcludedFromAuthz =
  (typeof OPERATIONS_EXCLUDED_FROM_AUTH)[number];

export type AuthenticatedUser = {
  id: string;
  zapierId: number;
  accountIds: number[];
  currentAccountId: number;
  isStaff: boolean;
  entitlements: AccountEntitlementsResponse;
};

export type AuthContext = {
  user?: AuthenticatedUser;
};

/**
 * These are the various asset IDs that can be used both in FGA permissions checks and in non-FGA authz checks.
 *
 * TODO: https://zapierorg.atlassian.net/browse/INTRFCS-3677
 * We need a centralized, tRPC / OpenAPI agnostic way to get these values from the request
 */
export type AuthzInput = {
  projectId?: string;
  blockId?: string;
  formId?: string;
  pageId?: string;
  blockActionId?: string;
  projectAuth?: {
    type?: ProjectAuthType;
  };
};

export type TrpcOperationAuthzRequest = {
  context: AuthContext;
  input: AuthzInput;
  permissionConfig?: TrpcPermissionConfig;
};

export type AssetType = components["schemas"]["AssetTypeEnum"];

export type TrpcPermissionConfig = {
  assetType?: AssetType; // Should always be "interface"

  permissionLevel: string;

  getAssetId?: (input: AuthzInput) => Promise<string | null>;

  enabled: boolean;
};

export type PermissionConfig = {
  zapierId: number;
  currentAccountId: number;
  assetType: AssetType;
  permissionLevel: string;
  assetId: string;
};

export type OperationIdsThatNeedAuthz = Exclude<
  keyof InterfaceOperations,
  OperationsExcludedFromAuthz
>;

export type InterfaceOperationForAuthz = {
  [Id in OperationIdsThatNeedAuthz]: {
    operationId: Id;
    parameters: InterfaceOperations[Id]["parameters"];
    body?: InterfaceOperations[Id] extends { requestBody?: infer RequestBody }
      ? RequestBody
      : undefined;
  };
}[OperationIdsThatNeedAuthz];

export type OperationAuthzRequest = InterfaceOperationForAuthz & {
  context: AuthContext;
};

export type OperationPermissionConfig = {
  assetType: AssetType;
  permissionLevel: string;
  enabled: boolean;
};
